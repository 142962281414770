import React from 'react';
import PropTypes from 'prop-types';
import {GatsbyImage} from 'gatsby-plugin-image';

import './style.scss';

// TODO add optional zoom on click
// TODO add optional link on image
const ContentImage = ({fluid, legend}) => {
	return (
		<div className={'gst-content-image'}>
			{fluid ? (
				<GatsbyImage image={fluid} />
			) : (
				<div className={'ptr-wire-image'} style={{height: '300px'}} />
			)}
			{legend ? (
				<span className={'gst-content-image-legend'}>
					<p>{legend}</p>
				</span>
			) : null}
		</div>
	);
};

ContentImage.propTypes = {
	fluid: PropTypes.object,
	legend: PropTypes.string,
};

export default ContentImage;
