import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import ContentImage from '../../../../components/ContentImage';

import {
	HighlightedParagraph,
	HighlightedTitle,
} from '../../../../components/highlightedText';
import Breadcrumb from '../../../../components/Breadcrumb';
import {lang} from '../../../../helpers/lang';

const SustainableUrbanDevelopment = ({pageContext}) => {
	const data = useStaticQuery(graphql`
		{
			background: file(
				relativePath: {eq: "pages/work/sectors/urbanLandResources.jpg"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			landVisat: file(
				relativePath: {eq: "pages/work/sectors/urbanLandResources/u01.png"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			urbanGugdm: file(
				relativePath: {eq: "pages/work/sectors/urbanLandResources/u02.png"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			greenMonitor: file(
				relativePath: {eq: "pages/work/sectors/urbanLandResources/u03.png"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			slumDetector: file(
				relativePath: {eq: "pages/work/sectors/urbanLandResources/u04.png"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			urbanHeatIslands: file(
				relativePath: {eq: "pages/work/sectors/urbanLandResources/u05.png"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout theme={pageContext.sector.theme}>
			<SEO title={pageContext.sector.title} lang={lang} />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<Breadcrumb crumbs={pageContext?.crumbs} />
				<HighlightedTitle>Sustainable urban development</HighlightedTitle>
				<HighlightedParagraph>
					Geospatial solutions for livable and vibrant cities and regions.
				</HighlightedParagraph>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<Anchors>
							<a href={'#land-assets-monitoring'}>Land Assets Monitoring</a>
							<a href={'#urban-expansion'}>Urban Expansion</a>
							<a href={'#green-areas-and-public-spaces'}>
								Promoting Green and livable cities
							</a>
							<a href={'#slum-upgrading-and-poverty-reduction'}>
								Slum upgrading and poverty reduction
							</a>
							<a href={'#urban-climate-resilience'}>
								Urban Climate Resilience – Nature Based Solutions
							</a>
						</Anchors>

						<PersonBadge
							note={pageContext.sector.contactNote}
							email={pageContext.sector.contactPerson}
						/>
					</Subnavigation>
				</Content>
			</Block>
			<Block light>
				<Content>
					<p>
						Over the last decades, demographic and economic changes have
						propelled cities and urban centers to become the principal habitat
						of humankind. This trend is going to even increase in years to come
						and cities and urbanized areas are now on the front line of the
						management of rapid changes and play a leading role in the global
						sustainable development agenda. It is only through cities that
						global challenges in poverty reduction, social cohesion, sustainable
						economic growth, environmental responsiveness and climate change
						adaptation may be addressed adequately.
					</p>
					<p>
						Our geospatial solutions support urban practitioners both on a
						strategic and operational level in the complex process of the city
						assets optimization balancing a resilient, adaptable,
						environmentally responsible, inclusive and economically vibrant
						city. We assist in responding effectively to these societal
						challenges and provide actionable intelligence supporting
						sustainability and operational efficiency whilst substantially
						reducing the cost of monitoring urban changes and environmental
						risks.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'land-assets-monitoring'}>Land Assets Monitoring</h2>
					<p>
						Urban planning information is historically very heterogeneous,
						making it challenging to feed into standard processes for project
						preparatory, design, implementation, monitoring or communication
						activities, requiring local data adaptation on a case-by-case basis.
						Our solutions provide a valuable source of standard, harmonized data
						to keep these adaptation costs low. Up-to-date information on land
						use and land cover assets quantity, availability, structure and
						distribution allows the evaluation of the ongoing land changes and
						their impact on city or region development potential. All this
						available on a long temporal scale provides actionable information
						for operational land assets management and improvement
						prioritization for city managers and private businesses.
					</p>
					<ContentImage
						fluid={
							data
								? data.landVisat.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						legend={
							<>
								Land Assets Monitoring example from application for Mandalay,
								Myanmar as presented on VISAT Exploration platform. ©GISAT
							</>
						}
					/>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'urban-expansion'}>Urban Expansion</h2>
					<p>
						Projected massive urban expansion trends require significant actions
						to affect future growth trajectories to minimize its impact on
						global biodiversity and vegetation carbon losses. Our solutions help
						to monitor their sustainable basis by supporting planning their
						future locations and magnitudes. Overall dynamic and major
						urbanisation processes like an extension (sprawling), densification
						or land recycling can be identified in rich spatial-temporal context
						quickly and accurately. Mastering urban expansion prevents
						overexploiting of environmental resources and increases the urban
						population's quality of life and safety.
					</p>
					<ContentImage
						fluid={
							data
								? data.urbanGugdm.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						legend={
							<>
								Long term urban expansion analysis based on World Settlement
								Footprint Evolution data. ©GISAT, WSF Evolution data ©DLR
							</>
						}
					/>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'green-areas-and-public-spaces'}>
						Promoting Green and livable cities
					</h2>
					<p>
						Green areas and public spaces are recognized as significant factors
						contributing to the overall livability of the city. Green
						infrastructure is a crucial driver of nature-based solutions in
						cities. Our solutions provide a range of tailored indicators to
						describe the quantity, quality and distribution of public spaces
						(urban green areas, public areas and street space) in the city,
						including their evolution over time. Our solutions offer a
						systematic and contextualized city-wide understanding of urban green
						and public spaces in place and their improvement potential. It
						significantly contributes to the related green and livable cities
						planning and upgrading activities. Green (and blue) infrastructure
						provides also a base for cooling and absorbing capacity to improve
						city resilience to climate impacts, often resulting also in other
						environmental, economic, and social co-benefits.
					</p>
					<ContentImage
						fluid={
							data
								? data.greenMonitor.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						legend={
							<>
								GreenMonitor - AI supported EO workflow for green and open areas
								monitoring. Example: Green and Open Areas monitoring and
								analytics in Dhaka, Pakistan. ©GISAT
							</>
						}
					/>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'slum-upgrading-and-poverty-reduction'}>
						Slum upgrading and poverty reduction
					</h2>
					<p>
						In many cities worldwide slum areas and slum populations are on
						growth. Uncontrolled expansion of urban areas undermines the
						efficiencies of compact urban living and marginalizes low-income
						populations in peripheral parts of cities, often in dense informal
						settlements - subject to substandard housing quality, impaired
						accessibility and insufficient provision of basic services. Our
						solutions support authorities addressing the specific needs and
						challenges related to slum management. Up-to-date identification,
						delineation and characterization of slums at the city scale helps in
						better slum upgrade planning and prioritization of interventions for
						poverty reduction.
					</p>
					<ContentImage
						fluid={
							data
								? data.slumDetector.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						legend={
							<>
								SlumDetector - AI supported EO workflow for slum monitoring.
								Example: Slum heatmap for Dhaka, Pakistan. ©GISAT
							</>
						}
					/>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'urban-climate-resilience'}>
						Urban Climate Resilience – Nature Based Solutions
					</h2>
					<p>
						Sustainable city planning requires hazards to be mitigated where
						they can be anticipated, and specifically urban structures must be
						managed to avoid the consequences of climate change. These require
						consistent and up-to-date identification of related risks due to
						flooding, subsidence or other natural or man-made factors and
						avoidance of urban development in risk zones. Our solutions help
						identify locations prone to hazards and to evaluate associated
						risks. They provide actionable intelligence supporting all phases of
						risk and disaster management cycle, reducing the cost of monitoring
						as well as potential harmful impact to urban assets and population.
						At the same time, the potential to deploy more nature-based
						approaches as prevention or mitigation measures can be supported
						too. Nature based solutions (NBS) provides multi-functional and
						cost-effective way to increase city risk resilience, at the same
						time reducing heat waves, pollution and energy costs and improving
						public health by regenerating urban spaces.
					</p>
					<ContentImage
						fluid={
							data
								? data.urbanHeatIslands.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						legend={
							<>
								Nature Based Solution support example: Urban Heat Island
								analysis for various green scenarios for urban designs of
								Masaryk Square, Hodonin, Czech Republic. ©GISAT and VITO
							</>
						}
					/>
				</Content>
			</Block>
		</Layout>
	);
};

SustainableUrbanDevelopment.propTypes = {
	pageContext: PropTypes.object.isRequired,
};

export default SustainableUrbanDevelopment;
